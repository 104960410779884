@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'NixieOne-Regular';
  src: url('../fonts/NixieOne-Regular.ttf');
}

@font-face {
  font-family: 'Barlow-Light';
  src: url('../fonts/Barlow-Light.ttf');
}


@font-face {
  font-family: 'Barlow-ExtraLight';
  src: url('../fonts/Barlow-ExtraLight.ttf');
}


// Font Family
$montserrat: "Poppins", sans-serif;
$abrilfatface: "Abril Fatface", cursive;
$cormorantgaramond: "Cormorant Garamond";
$montserrat: "Montserrat";
$nixieone: "NixieOne-Regular";
$barlowlight: "Barlow-Light";
$barlowextralight: "Barlow-ExtraLight";

// text Colors ---------------

$white: #ffffff;
$black: #000000;
$grey: #f6f7f8;
$red: #f6f7f8;
$green: #f6f7f8;

// bg Colors ---------------

$bg-white: #ffffff;
$bg-black: #000000;
$bg-grey: #f6f6f8;
$bg-purple: #f0e0ff;

// Heading Color
$heading-color: #000000;

// Theme Color
$theme-color: #7a7a7a;

// Responsive Variables
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs-layout: "only screen and (max-width: 767px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$xxs-layout: "only screen and (min-width: 320px) and (max-width: 479px)";
